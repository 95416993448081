@import "../variables";

.eeh-navigation-sidebar {
  border-radius: 0;
  border-top: none;
  border-right: none;
  border-left: none;

  > .navbar-collapse {
    padding-right: 0;
    padding-left: 0;

    > ul.sidebar-nav {
      /* Indent second level menu items */
      > li > ul > li > a {
        padding-left: $sidebar-menu-item-padding-left * 2;
      }
      /* Indent third level menu items */
      > li > ul > li > ul > li > a {
        padding-left: $sidebar-menu-item-padding-left * 3;
      }
    }
  }

  .navbar-default > .active {
    background-color: $menu-item-active-bg;
    color: $menu-item-active-color;
  }

  .sidebar-search {
    .sidebar-search-input {
      a {
        position: relative;
        display: block;
        padding: 10px 15px;
      }
    }
  }

  .sidebar-arrow {
    line-height: 1.5em;
  }

  .sidebar-nav {
    > li a {
      color: $navbar-default-link-color;

      &:hover,
      &:focus {
        color: $navbar-default-link-hover-color;
        background-color: $navbar-default-link-hover-bg;
      }
    }
    > .active > a {
      &,
      &:hover,
      &:focus {
        color: $navbar-default-link-active-color;
        background-color: $navbar-default-link-active-bg;
      }
    }
    > .disabled > a {
      &,
      &:hover,
      &:focus {
        color: $navbar-default-link-disabled-color;
        background-color: $navbar-default-link-disabled-bg;
      }
    }
  }
}

@media(min-width: 768px) {
  .eeh-navigation-sidebar {
    z-index: 1;
    position: absolute;
    width: 250px;
  }

  .eeh-navigation-sidebar.sidebar-text-collapsed {
    width: 50px;
  }

  #eeh-navigation-page-wrapper.sidebar-text-collapsed {
    margin-left: 50px;
  }

  .sidebar-nav-nested.text-collapsed {
    position: absolute;
    left: $sidebar-nav-nested-collapsed-left;
    top: 0;
    width: $sidebar-nav-nested-collapsed-width;
    background-color: $sidebar-nav-nested-collapsed-bg;

    a {
      padding-left: $sidebar-menu-item-padding-left !important;
    }

    .sidebar-nav-nested.text-collapsed {
      position: relative;
      left: 0;

      a {
        padding-left: $sidebar-menu-item-padding-left * 2 !important;
      }
    }
  }
}

#eeh-navigation-page-wrapper {
  padding: 0 15px;
  min-height: 568px;
  background-color: #fff;
}

#eeh-navigation-page-wrapper.sidebar-invisible {
  margin-left: 0;
  border-left: none;
}

@media(min-width:768px) {
  #eeh-navigation-page-wrapper {
    position: inherit;
    margin: 0 0 0 250px;
    padding: 0 30px;
    border-left: 1px solid #e7e7e7;
  }
}

@import "variables";
@import "navbar/navbar";
@import "sidebar/sidebar";
@import "sidebar/transcluded-wrapper";
@import "search-input/search-input";

.eeh-navigation {
  margin-bottom: 0;

  a {
    cursor: pointer;
  }

  .icon-fw {
    width: 1.28571429em;
    text-align: center;
  }

  .float-right {
    float: right !important;
    margin-right: -10px;

    ~ .float-right {
      margin-right: 0;
    }
  }
}

@import "../../variables";

// Menu Items
$menu-item-active-bg: $navbar-default-link-active-bg !default;
$menu-item-active-color: $navbar-default-link-active-bg !default;

// Sidebar Menu Items
$sidebar-menu-item-padding-left: 15px !default;

$sidebar-nav-nested-collapsed-left: 51px !default;
$sidebar-nav-nested-collapsed-width: 250px !default;
$sidebar-nav-nested-collapsed-bg: $navbar-default-bg !default;

